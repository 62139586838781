<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="80px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item label="项目" prop="projectId">
                        <project-select v-model="formData.projectId">
                        </project-select>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="cover" label="列表图">
                        <single-upload v-model="formData.cover" compress></single-upload>
                        <div class="form_tip">1029x420</div>
                    </el-form-item>
                    <el-form-item
                        prop="content"
                        label="详情"
                        style="width: calc(100vw - 450px)">
                        <rich-text v-model="formData.content"></rich-text>
                    </el-form-item>
                    <!-- <el-form-item prop="detail" label="详情图">
                        <multi-upload v-model="formData.detail" accept="image/*" compress></multi-upload>
                        <div class="form_tip">宽度1080px，长度2000px以内，可上传多张</div>
                    </el-form-item> -->
                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import RichText from '../components/RichText.vue';
import SingleUpload from '../components/SingleUpload.vue';
export default {
    components: { RichText, SingleUpload },
    name: 'ActivityEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('activity/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                cover: [
                    {
                        required: true,
                        message: '请上传列表图',
                        trigger: 'blur'
                    }
                ],
                detail: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length) {
                                callback();
                            } else {
                                callback(new Error('请上传详情'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                projectId: [{ required: true, message: '请选择项目' }]
            }
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/activity/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activity/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
